"use client";

import Link from "next/link";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import type { WpMenuItem } from "@nextwp/core";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import Button from "../ui/button";

interface MenuItem extends WpMenuItem {
  acf?: {
    variant?: "default" | "button";
  };
}

export function DesktopMenu({
  menuItems,
  className,
}: {
  menuItems?: MenuItem[];
  className?: string;
}) {
  return (
    <nav className={cn("items-center", className)}>
      {menuItems?.map((menuItem) => {
        if (menuItem.childItems?.length) {
          return (
            <DropdownMenu key={menuItem.id}>
              <DropdownMenuTrigger asChild>
                <Button
                  className={cn(
                    "!text-[hsla(0, 0%, 8%, 1)]",
                    menuItem.acf?.variant === "button" && "text-white",
                  )}
                  variant={
                    menuItem.acf?.variant === "button" ? "default" : "link"
                  }
                >
                  <span className="text-[rgb(31, 41, 51)] text-lg">
                    {menuItem.label}
                  </span>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="inline-block h-5 w-5"
                  />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent>
                {menuItem.childItems.map((childItem) => {
                  if (!childItem.path) return null;
                  return (
                    <DropdownMenuItem asChild key={childItem.id}>
                      <Link
                        className={cn(
                          "group !block p-2 hover:bg-white hover:text-primary focus:bg-white focus:text-primary",
                          className,
                        )}
                        href={childItem.path}
                      >
                        {childItem.label ? (
                          <span
                            className="block text-base font-medium"
                            dangerouslySetInnerHTML={{
                              __html: childItem.label,
                            }}
                          />
                        ) : null}

                        {childItem.description ? (
                          <span
                            className="mt-1 block text-[14px] text-[#CCCCCC] group-hover:text-primary group-focus:text-primary"
                            dangerouslySetInnerHTML={{
                              __html: childItem.description,
                            }}
                          />
                        ) : null}
                      </Link>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        }

        return (
          <Button
            asChild
            className={cn(
              "!text-[hsla(0, 0%, 8%, 1)]",
              menuItem.acf?.variant === "button" && "ml-5 text-white",
            )}
            key={menuItem.id}
            variant={menuItem.acf?.variant === "button" ? "default" : "link"}
          >
            <Link
              dangerouslySetInnerHTML={{ __html: menuItem.label || "" }}
              href={menuItem.path || ""}
            />
          </Button>
        );
      })}
    </nav>
  );
}
